import UserService from '../services/user.service';

const initialState = {
  dataType: [],
  isDataLoaded: false,
};

export const dataType = {
  namespaced: true,
  state: initialState,
  actions: {
    async fetchData({ commit, state }) {
      if (!state.isDataLoaded) {
        try {
          const response = await UserService.getAllTypes();
          console.log('response XXXXXXX', response);
          commit('setDataType', response.data);
          commit('setDataLoaded', true);
        } catch (error) {
          console.error(
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
          );
        }
      }
    }
  },
  mutations: {
    setDataType(state, data) {
      state.dataType = data;
    },
    setDataLoaded(state, loaded) {
      state.isDataLoaded = loaded;
    }
  },
  getters: {
    dataType: state => state.dataType,
    isDataLoaded: state => state.isDataLoaded,
  }
};

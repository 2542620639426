import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
// import store from '../store'; // Importer le store
const API_URL = 'https://admin.trc.asap.cloud/api/';
// const API_URL = 'http://127.0.0.1:8000/api/';
const TOKEN_KEY = 'userToken';

class AuthService {

   login(user) {
      return axios
         .post(API_URL + 'login', {
         username: user.username,
         password: user.password
         })
         .then(response => {
            if (response.data.token) {
               localStorage.setItem(TOKEN_KEY, JSON.stringify(response.data.token));
            }
            return response.data.token;
         });
   }

   logout() {
      localStorage.removeItem(TOKEN_KEY);
   }

   isTokenExpired() {
      const userToken = localStorage.getItem(TOKEN_KEY);
      if (!userToken) {
         return null;
      }
      const user = JSON.parse(userToken);
      try {
         const decoded = jwtDecode(user);
         const currentTime = Date.now() / 1000;
         const expirationTime = decoded.exp;

         const timeRemaining = (expirationTime - currentTime);
         // console.log('le jeton est toujours valide, il reste : ', Math.floor(timeRemaining)+ ' secondes')

         if (timeRemaining < 20 ) {
            return false;
         } else {
            return user;
         }
      } catch (error) {
         return null;
      }
   }

   startTokenExpirationCheck(store, interval = 10000) { 
      setInterval(() => {
        if ( !this.isTokenExpired() ) {
          store.dispatch('auth/logout'); 
        }
      }, interval);
    }

}

const authService = new AuthService();

export default authService;

<template>
  <div id="app" class="flex bg-white min-h-screen">
    <div class="flex-grow text-gray-800">
        <Header v-if="loggedIn"/>
        <div class="content">
          <!-- <transition name="slide" mode="out-in"> -->
            <router-view />
          <!-- </transition> -->
        </div>
    </div>
  </div>
</template>

<script>
import Header from "./layouts/Header.vue";
import AuthService from './services/auth.service';
import store from './store';
// import { mapActions } from 'vuex';
// import { mapState } from 'vuex';
export default {
  components: {
      Header,
  },
  data() {
    return {
    };
  }, 
  mounted() {
    AuthService.startTokenExpirationCheck(store);
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
  }

};
</script>
import { createWebHistory, createRouter } from "vue-router";
import Home from "./pages/Home.vue";
import Login from "./pages/Login.vue";
import store from './store';

// lazy-loaded
const User = () => import("./pages/User.vue")
const Profils = () => import("./pages/Profils.vue")
const Profil = () => import("./components/profil/Profil.vue")

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/home",
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/user",
    name: "user",
    component: User,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profils',
    name: 'profils',
    component: Profils,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'profil-:id',
        name: 'UserDetails',
        component: Profil,
        props: true,
        meta: {
          requiresAuth: true,
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
}); 

router.beforeEach((to, from, next) => {
  const loggedIn = store.state.auth.status.loggedIn;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (!loggedIn && requiresAuth) {
    next('/login');
  } else if (loggedIn && to.name === '/login') {
    next('/');
  } else {
    next();
  }
});



export default router;
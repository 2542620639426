import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://admin.trc.asap.cloud/api/';
const PUT_URL = 'https://admin.trc.asap.cloud/put/';
const POST_URL = 'https://admin.trc.asap.cloud/post/';
const GET_URL = 'https://admin.trc.asap.cloud/get/';
const DELETE_URL = 'https://admin.trc.asap.cloud/delete/';
// const API_URL = 'http://127.0.0.1:8000/api/';
// const PUT_URL = 'http://127.0.0.1:8000/put/';
// const POST_URL = 'http://127.0.0.1:8000/post/';
// const GET_URL = 'http://127.0.0.1:8000/get/';
// const DELETE_URL = 'http://127.0.0.1:8000/delete/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getCurrentUserInfos() {
    return axios.get(API_URL + 'users', { headers: authHeader() });
  }
  
  getIndividusSearch(page, limit, query) {
    return axios.get(API_URL + `individus_tableaus?page=${page}&itemsPerPage=${limit}${query}`, { headers: authHeader() });
  }
  
  getIndividu(id) {
    return axios.get(API_URL + `individus/${id}`, { headers: authHeader() });
  }
  
  putIndividus(id, data) {
    const headers = {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json',
      ...authHeader()
    };
    return axios.put(API_URL + `individus/${id}`, data, { headers });
  }

  

/* ================================================================ */
/* ======================== TELEPHONE ========================= */
/* ================================================================ */
 

// Delete Telephone
  deleteTelephone(telephone, data) {
    const headers = {
        'Accept': 'application/ld+json',  
        'Content-Type': 'application/ld+json',
        ...authHeader()
    };
    const datas = {
        typeTelephoneId: data.typeTelephoneId
    };
    console.log('datas', datas)

    return axios.delete(DELETE_URL + `telephones/${telephone}`, datas, { headers });  
  }




/* ================================================================ */
/* ======================= OPERATION CRUD ========================= */
  /* ================================================================ */
// Get Item
  getItem(id, typeEntity) {
      const headers = {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json',
          ...authHeader()
      };
      return axios.get(GET_URL + `${typeEntity}/${id}`, { headers });
  }

// Update Item
  updateItem(id, datas, typeEntity) {
      const headers = {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json',
          ...authHeader()
      };
      return axios.put(PUT_URL + `${typeEntity}/${id}`, datas, { headers });
  }

// Post Item
  postItem(id, datas, typeEntity) {
      const headers = {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json',
          ...authHeader()
      };
      console.log('TEST POSTTTT', datas)
      return axios.post(POST_URL + `${typeEntity}/${id}`, datas, { headers });
  }

// Delete Item
  deleteItem(id, typeEntity, secondArg, thirdArg) {
      const headers = {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json',
          ...authHeader()
      };
    
      var url = '';
      if (secondArg && thirdArg) {
        url = `${typeEntity}/${id}/${secondArg}/${thirdArg}` ;
      } else {
        url = secondArg ? `${typeEntity}/${id}/${secondArg}` : `${typeEntity}/${id}`;
      }
    
      return axios.delete(DELETE_URL + `${url}`, { headers });
  }
 






  // getIndividusByName(name) {
  //   return axios.get(API_URL + `individus?name=${name}`, { headers: authHeader() });
  // }

  // addFreelance(user) {
  //   return axios.post(API_URL + 'individus', {
  //     name: user.name,
  //     email: user.email
  //   }, { headers: authHeader() });
  // }

  getAllTypes() {
    return axios.get(API_URL + `get/types`, { headers: authHeader() });
  }

  
  deleteIndividus(id) {
    console.log('id', id)
    return axios.delete(API_URL + 'individus/'+id, { headers: authHeader() });
  }

  

}

export default new UserService();

<template>
    <header class="flex items-center h-20 px-6 sm:px-8 bg-gray-50 border-b border-gray-200 sticky top-0 z-50">
        <nav class="flex items-center">
            <router-link to="/profils" class="inline-flex items-center py-3">
                <img class="h-6 w-6" :src="require(`@/assets/icon-profiling.png`) " alt="profiling">
                <span class="ml-2" x-show="menu">Profiling</span>
            </router-link>
        </nav>
        <div class="flex flex-shrink-0 items-center ml-auto">
                <button class="relative inline-flex items-center p-2 hover:bg-gray-100 focus:bg-gray-100 rounded-lg"
                    @click="panel = !panel">
                    <div class="md:flex md:flex-col md:items-end md:leading-tight">
                        <span class="">Alain</span>
                    </div>
                    <span class="h-7 w-7 ml-1 sm:ml-2 mr-2 bg-gray-100 rounded-full overflow-hidden">
                        <img src="https://png.pngtree.com/png-vector/20190329/ourmid/pngtree-vector-avatar-icon-png-image_889567.jpg"
                            alt="user profile photo" class="h-full w-full object-cover">
                    </span>
                </button>
            <div class="border-l pl-3 ml-2 space-x-1">
                <button
                    class="relative p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:bg-gray-100 focus:text-gray-600 rounded-full"
                    @click.prevent="logOut">
                    <span class="sr-only">Log out</span>
                    <img class="h-7 w-7" :src="require(`@/assets/icon-logout.png`)" alt="logout">
                </button>
            </div>
        </div>



    </header>
</template>
<script>
export default {
    // props: {
    //   currentUser: {type: Boolean, require: true},
    // },
    data(){
        return {
            menu: null
        }
    },
    mounted() {
    },
    methods: {
      logOut() {
        this.$store.dispatch('auth/logout');
        this.$router.push('/login');
      }
    }
};
</script>

<style scoped lang="scss"> 
</style>
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import 'vue3-easy-data-table/dist/style.css'; 
import Vue3EasyDataTable from 'vue3-easy-data-table';  
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
 
DataTable.use(DataTablesCore);

import './assets/css/index.css';

createApp(App)
  .use(router)
  .use(store)
  .use(DataTable)
  .component('EasyDataTable', Vue3EasyDataTable)
  .mount("#app");

// index.js
import { createStore } from 'vuex';
import { auth } from './auth.module';
import { dataType } from './data.module';

const store = createStore({
  modules: {
    auth,
    dataType,
  },
});

export default store;
